import { GatewayControllerApi as Ctrl } from '@rallycry/api-suite-typescript/dist/apis/GatewayControllerApi'
import { IndexResource } from '@rallycry/api-suite-typescript/dist/models/IndexResource'
import { UserControllerApi } from '@rallycry/api-suite-typescript/dist/apis/UserControllerApi'
import { is } from 'date-fns/locale'
import { EntityOptions, useReadEntity } from '../useEntity'
import { useUserAccount } from '../user/useUserAccount'
import { useUserMeta } from '../user/useUserMeta'
import { useConfig } from './useConfig'
import { useFirebase } from '@/components/providers/site/FirebaseProvider'
import { tryExtractJsonError } from '@/core/utils'
import { ErrorCode } from '@/core/error-code'
import { useController } from '@/core/hooks/useSWRApi'

export const useGateway = (options?: EntityOptions) => {
  const config = useConfig()
  const { account } = useUserAccount()
  const { ctrl: userCtrl } = useController(UserControllerApi)
  const { ctrl } = useController(Ctrl)
  const { read: meta } = useUserMeta()
  const { user } = useFirebase()

  const read = async (
    skipCache: boolean,
    organization: string,
    metas?: Record<string, any>
  ) => {
    try {
      let res = await ctrl({
        skipCache,
        metas:
          metas?.personal || metas?.expiredCode
            ? { personal: metas.personal, expiredCode: metas.expiredCode }
            : undefined
      }).readGatewayIndex({ organization })
      let step = getStep(res)
      if (step === Step.RefreshGateway) {
        res = await ctrl({
          skipCache: true,
          metas:
            metas?.personal || metas?.expiredCode
              ? { personal: metas.personal, expiredCode: metas.expiredCode }
              : undefined
        }).readGatewayIndex({ organization })
        step = getStep(res)
      }

      if (step === Step.Complete) {
        try {
          window.localStorage.removeItem(
            `gatekeeper_${config.orgKey}_${account?.id}`
          )
        } catch (e) {
          // fail silently, cookies disabled
        }
      }

      return { step, res }
    } catch (error) {
      const err = await tryExtractJsonError(error)

      const isClaimed =
        err?.issues?.[0]?.code === ErrorCode.ContactAccountAlreadyClaimed

      if (isClaimed) {
        return { step: Step.AlreadyClaimed }
      }

      if ([401, 403].includes((error as any).status)) {
        return { step: Step.Unauthorized, res: {} }
      }

      throw error
    }
  }

  const entityRead = (
    skipCache: boolean,
    metas: Record<string, any>,
    idOrKey: string
  ) => {
    return read(skipCache, idOrKey, metas)
  }

  const entity = useReadEntity({
    key: 'useGateway',
    idOrKey: config.orgKey,
    paused: !user,
    metas: [meta],
    ...options,
    read: ({ idOrKey }, metas) => entityRead(false, metas, idOrKey)
  })

  const entityReadMutate = entity.read.mutate
  const refresh = async () => {
    await entityReadMutate(
      await entityRead(true, meta.data, config.orgKey),
      false
    )
  }

  const skipJoin = async () => {
    await userCtrl().skipAddBusinessCommunities()
    await refresh()
  }

  const skipDiscord = async () => {
    await userCtrl().skipConnectDiscord()
    await refresh()
  }

  const skipCompeting = async () => {
    await userCtrl().skipParticipateInCompetition()
    await refresh()
  }

  // gatekeeper communities
  const listedCommunities =
    entity.read.data?.res?.cta?.listedCommunities
      ?.map(it => ({
        ...it,
        _expanded: entity.read.data?.res?.cta?.expanded
      }))
      ?.sort((a, b) => Number(!!b?._links?.join) - Number(!!a?._links?.join)) ||
    []

  return {
    ...entity,
    step: entity.read.data?.step || Step.Complete,
    isRootAdmin: !!entity.read.data?.res?.root?._links?.self,
    res: entity.read.data?.res || {},
    listedCommunities,
    expiredAccessGroups: entity.read.data?.res?.cta?.expiredAccessGroups,
    account,
    isComplete: account && entity.read.data?.step === Step.Complete,
    refresh,
    skipJoin,
    skipDiscord,
    skipCompeting,
    featPartyFinderActivity:
      !!entity.read.data?.res?.partyFinderActivity?._links?.create
  }
}

export enum Step {
  Unknown = 1,
  Unauthorized = 2,
  ProfileInfo = 3,
  GamePreferences = 4,
  VerifyEmail = 5,
  JoinCommunity = 6,
  CreateCommunity = 7,
  VerifyAdditionalEmail = 8,
  AlreadyClaimed = 9,
  LinkDiscord = 10,
  RefreshGateway = 11,
  Complete = 12,
  DefaultMode = 13,
  Error = 14
}

export const getStep = (model: IndexResource): Step => {
  const stages: [Step, (m: IndexResource) => boolean][] = [
    [Step.ProfileInfo, m => !!m?.cta?.user_account?._links?.create],
    [Step.GamePreferences, m => !!m?.cta?.profile_games?._links?.add],
    [Step.VerifyEmail, m => !!m?.cta?.user?._links?.verify],
    [Step.JoinCommunity, m => !!m?.cta?.listedCommunities],
    [Step.CreateCommunity, m => !!m?.cta?.community?._links?.create],
    [Step.VerifyAdditionalEmail, m => !!m?.cta?.verification?._links?.create],
    [Step.LinkDiscord, m => !!m?.cta?.user?._links?.['connect-discord']],
    [Step.RefreshGateway, m => !!m?.cta?.user?._links?.retry]
  ]

  for (const stage of stages) {
    const [step, check] = stage
    if (check(model)) {
      return step
    }
  }

  return Step.Complete
}
